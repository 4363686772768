.Header :global {
    .container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        h1 {
            text-shadow: 0px 0px 4px rgba(black, 0.5);
            margin-bottom: 0;
        }
    }
}