@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.NavbarToggler {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;

    &:hover {
        background-color: rgba(white, 0.2);
    }

    &:focus {
        outline: none;
    }
}

@include media-breakpoint-up(md) {
    .NavbarToggler {
        display: none;
    }
}