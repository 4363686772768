.App {

  .page-header {
    background: linear-gradient(to bottom, rgba(black, 0.7), rgba(white, 0.0) 25%);
  }
  .page-header-image {
    background-image: url('../img/page-header.jpg');
    filter: brightness(1.5);
  }

  .wrapper {
    
  }
}
