.footer {
    background-color: #efefef;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 1rem;
    padding-bottom: 1rem;
}