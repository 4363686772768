.Main {
    background-color: rgba(black, 0.05);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.MainList {
    list-style: none;
    padding-left: 0;
}

.MainListItem {
    letter-spacing: 0.025rem;
    padding-bottom: 0.75rem;
}