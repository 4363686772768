.contact {
    padding: 24px 0;

    :global {

        h4 { margin-bottom: 32px; }

        label { padding-left: 10px; }

        .input-group:focus-within {
            .input-group-prepend, .input-group-append {
                .input-group-text {
                    border-color: #f96332;
                }
            }
        }

        .input-group.has-error {
            .input-group-prepend, .input-group-append {
                .input-group-text {
                    border-color: red !important;
                    color: red !important;
                }
            }
            .form-control {
                &:not(textarea) {
                    border-left: none;
                    border-right: none;
                }
                border-color: red !important;
            }
        }

        textarea {
            border: 1px solid #E3E3E3 !important;
            border-radius: 30px !important;
            padding: 10px 18px 10px 18px !important;
            margin-bottom: 10px;
            flex: 1;
            max-height: 100% !important;


            &:focus {
                border-color: #f96332 !important;
            }

            &.has-error {
                border-color: red !important;
            }
        }

        .btn:disabled {
            cursor: not-allowed;
            &:hover {
                background-color: #f96332 !important;
            }
        }
    }
}